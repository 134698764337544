.layout-login-centered-boxed {
  height: initial;

  &__form {
    padding: 2rem;
    position: relative;
    // margin: 2rem 0;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    &__form {
      max-width: 26rem;
      min-width: calc(320px + 3rem * 2);
      padding: 3rem;
    }
  }
}