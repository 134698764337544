html,
body {
  height: 100%;
  position: relative;
}
.mdk-header-layout,
.mdk-drawer-layout,
.mdk-drawer-layout__content {
  height: initial;
  min-height: 100%;
}

.page > .page__container {
  &:not(.page__heading) {
    margin-top: $grid-gutter-width;
  }
}

.layout-app .page {
  margin-top: 0;
  padding-bottom: 0;
}

.py-grid {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
}

.page__container,
.page__heading-container {
  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}
.container-fluid {
  max-width: 1300px;
}

.page__heading {
  h1 {
    line-height: 1;
  }
  .lead {
    font-weight: 400;
  }
  padding-top: $grid-gutter-width /2;
  padding-bottom: $grid-gutter-width/2;
  background:#ffffff;

  @include media-breakpoint-up(xl) {
    &:not(.page__heading--xl_small) {
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
    }
  }
}

.page__header {
  background: white;
  box-shadow: 0 5px 15px 0 rgba(227,227,227,0.50);
  margin-bottom: $grid-gutter-width/2;
  @include media-breakpoint-up(xl) {
    margin-bottom: $grid-gutter-width;
  }
  z-index: 3;
  & + .page__header {
    z-index: 2;
  }
  position: relative;
}

// sticky subnav

.layout-sticky-subnav {
  .mdk-header-layout {
    overflow: initial;
  }
  .page__header {
    &-nav {
      position: sticky;
      // top: $navbar-height;
      z-index: 1;
    }
    &:first-child {
      margin-bottom: 0;
      box-shadow: none;
    }
  }
  .page__container {
    z-index: 0;
    position: relative;
  }
}

.mdk-header--shadow::after {
  box-shadow: 0 5px 15px 0 rgba(227,227,227,0.50);
  bottom: 0;
  // height: $navbar-height;
}

.projects-item {
  margin-bottom: 0 !important;
  padding-bottom: .4375rem;
  border-left: 1px dotted rgba(55, 77, 103, 0.2);
  margin-left: 10px;
}

.bottom-spacing {
  margin-bottom: $grid-gutter-width / 2;
  
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-width;
  }
}


@include media-breakpoint-up(md) {
  .price-range-width { width: 150px; }
}
.min-w-150 {
  min-width: 300px;
}
.min-w-300 {
  min-width: 300px;
}
.js-dragula-handle {
  cursor: move;
}

.h-150 { height:150px !important; }
.h-250 { height:250px; }
.h-calc-250 {
  height: calc(250px - 1.25rem * 2);
}

.z-0 {
  z-index: 0;
}
